import {Controller} from "stimulus";
import { map, some, sum, compact, every } from "lodash";
import {
  valueIsPresent,
  isValidNumber,
  isNumberLessThanOrEqualTo,
  isNumberGreaterThan,
  numberHasDecimalPlaces
} from "./helpers/validators";

export default class extends Controller {
  static targets = [
    "leasablePercentage",
    "leasableTotal",
    "extrasPercentage",
    "extrasTotal",
    "invoiceTotal",
    "calculationResult",
    "row",
    "rowsContainer",
    "checkbox",
    "leasableItemsCount",
    "extrasItemsCount",
    "itemTitleInput",
    "itemAmountInput",
    "addItemButton"
  ]

  connect() {
    this.loadTranslations();
    this.resetCalculations();
    this.updateCalculationsUI();
  }

  loadTranslations() {
    this.translations = {
      toggles: {
        leasable: this.data.get("leasableText"),
        extra: this.data.get("extraText")
      },
      statuses: {
        passStatus: this.data.get("passStatus"),
        failStatus: this.data.get("failStatus"),
        undeterminedStatus: this.data.get("undeterminedStatus")
      },
      validations: {
        itemDescriptionRequired: this.data.get("itemDescriptionRequiredValidationMessage"),
        amountIsRequired: this.data.get("amountIsRequiredValidationMessage"),
        amountMustBeAValidNumber: this.data.get("amountMustBeAValidNumberValidationMessage"),
        amountMustBeGreaterThanZero: this.data.get("amountMustBeGreaterThanZeroValidationMessage"),
        amountMustBeLessThanMax: this.data.get("amountMustBeLessThanMaxValidationMessage"),
        amountMustHaveAtMostTwoDecimalPlaces: this.data.get("amountMustHaveAtMostTwoDecimalPlacesValidationMessage")
      }
    };
  }

  resetCalculations() {
    this.leasablePercentage = 0;
    this.leasableTotal = 0;
    this.extrasPercentage = 0;
    this.extrasTotal = 0;
    this.invoiceTotal = 0;
    this.calculationResult = "undetermined";
    this.leasableItemsCount = 0;
    this.extrasItemsCount = 0;
  }

  setCalculationError() {
    this.calculationResult = "undetermined";
    this.leasableItemsCount = 0;
    this.extrasItemsCount = 0;
  }

  showCalculationsErrorUI() {
    this.leasablePercentageTarget.innerHTML = "--";
    this.leasableTotalTarget.innerHTML = "--";
    this.extrasPercentageTarget.innerHTML = "--";
    this.extrasTotalTarget.innerHTML = "--";
    this.invoiceTotalTarget.innerHTML = "--";
    this.calculationResultTarget.innerHTML = "--";
    this.leasableItemsCountTarget.innerHTML = "";
    this.extrasItemsCountTarget.innerHTML = "";

    // Remove all styling from percentages and calculation status container
    this.leasablePercentageTarget.classList.remove("fail", "pass");
    this.extrasPercentageTarget.classList.remove("fail", "pass");
    this.calculationResultTarget.classList.remove("fail", "pass");
  }

  updateCalculationsUI() {
    this.leasablePercentageTarget.innerHTML = this.formatAsPercentage(this.leasablePercentage);
    this.leasableTotalTarget.innerHTML = this.formatAsCurrency(this.leasableTotal);
    this.extrasPercentageTarget.innerHTML = this.formatAsPercentage(this.extrasPercentage);
    this.extrasTotalTarget.innerHTML = this.formatAsCurrency(this.extrasTotal);
    this.invoiceTotalTarget.innerHTML = this.formatAsCurrency(this.invoiceTotal);
    this.calculationResultTarget.innerHTML = this.calculationsStatusText(this.calculationResult);
    this.leasableItemsCountTarget.innerHTML = this.formatAsItemsCount(this.leasableItemsCount);
    this.extrasItemsCountTarget.innerHTML = this.formatAsItemsCount(this.extrasItemsCount);

    switch (this.calculationResult) {
      case "pass":
        this.calculationResultTarget.classList.remove("fail");
        this.calculationResultTarget.classList.add("pass");

        this.leasablePercentageTarget.classList.remove("fail");
        this.leasablePercentageTarget.classList.add("pass");

        this.extrasPercentageTarget.classList.remove("fail");
        this.extrasPercentageTarget.classList.add("pass");

        break;
      case "fail":
        this.calculationResultTarget.classList.add("fail");
        this.calculationResultTarget.classList.remove("pass");

        this.leasablePercentageTarget.classList.add("fail");
        this.leasablePercentageTarget.classList.remove("pass");

        this.extrasPercentageTarget.classList.add("fail");
        this.extrasPercentageTarget.classList.remove("pass");

        break;
      default:
        this.calculationResultTarget.classList.remove("fail", "pass");
        this.calculationResultTarget.classList.add("empty");

        this.leasablePercentageTarget.classList.remove("fail", "pass");

        this.extrasPercentageTarget.classList.remove("fail", "pass");
    }
  }

  calculationsStatusText(status) {
    return this.translations.statuses[`${status}Status`];
  }

  formatAsCurrency(value) {
    return (value).toLocaleString("en-US", { style: "currency", currency: "USD"});
  }

  formatAsPercentage(value) {
    return `${value}%`;
  }

  formatAsItemsCount(itemsCount) {
    if (itemsCount <= 0) {
      return "";
    }

    return ` (${itemsCount})`;
  }

  countExtraItems() {
    const extraItemsCount = this.checkboxTargets.reduce((accumulator, currentCheck) => {
      if (currentCheck.checked) {
        return accumulator;
      }

      return accumulator + 1;
    }, 0);

    return this.checkboxTargets.length - extraItemsCount;
  }

  isNumberGreaterThanZero(value) {
    return isNumberGreaterThan(0, value);
  }

  isNumberLessThanMax(value) {
    return isNumberLessThanOrEqualTo(99000, value);
  }

  hasDecimalPlaces(value) {
    return numberHasDecimalPlaces(value, 2);
  }

  fetchFieldValues(targets) {
    return map(targets, (target) => target.value.trim());
  }

  handleTitleChange(e) {
    this.validateTitleInput(e.currentTarget);
    this.calculate();
  }

  handleAmountChange(e) {
    this.validateAmountInput(e.currentTarget);
    this.calculate();
  }

  validateTitle(title) {
    return this.validateField(
      title,
      [{
        fn: valueIsPresent,
        errorMessage: this.translations.validations.itemDescriptionRequired
      }]
    );
  }

  validateAmount(amount) {
    return this.validateField(
      amount,
      [{
        fn: valueIsPresent,
        errorMessage: this.translations.validations.amountIsRequired
      },
      {
        fn: isValidNumber,
        errorMessage: this.translations.validations.amountMustBeAValidNumber
      },
      {
        fn: this.isNumberGreaterThanZero,
        errorMessage: this.translations.validations.amountMustBeGreaterThanZero
      },
      {
        fn: this.isNumberLessThanMax,
        errorMessage: this.translations.validations.amountMustBeLessThanMax
      },
      {
        fn: this.hasDecimalPlaces,
        errorMessage: this.translations.validations.amountMustHaveAtMostTwoDecimalPlaces
      }
      ]
    );
  }

  validateTitleInput(input) {
    const result = this.validateTitle(input.value);
    const error = result.errors.length > 0 ? result.errors[0] : "";

    this.toggleErrorForInput(input, error);

    return result.succeeded;
  }

  validateAmountInput(input) {
    const result = this.validateAmount(input.value);
    const error = result.errors.length > 0 ? result.errors[0] : "";

    this.toggleErrorForInput(input, error);

    return result.succeeded;
  }

  validateField(value, validators) {
    const errors = compact(validators.map((validator) => {
      if (!validator.fn(value)) {
        return validator.errorMessage;
      }
    }));

    return {
      succeeded: errors.length === 0,
      errors: errors
    }
  }

  toggleErrorForInput(input, error) {
    const errorMessageContainer = input.closest(".field-container").querySelector(".error-message");

    if (error) {
      input.classList.add("error");
      errorMessageContainer.classList.remove("hidden");
      errorMessageContainer.innerHTML = error;
    } else {
      input.classList.remove("error");
      errorMessageContainer.classList.add("hidden");
      errorMessageContainer.innerHTML = "";
    }
  }

  allTitlesAreValid(inputs) {
    const validationResults = map(inputs, (input) => this.validateTitleInput(input));

    return every(validationResults);
  }

  allAmountsAreValid(inputs) {
    const validationResults = map(inputs, (input) => this.validateAmountInput(input));

    return every(validationResults);
  }

  calculate() {
    const rows = this.rowTargets;

    const titles = this.fetchFieldValues(this.itemTitleInputTargets);
    const amounts = this.fetchFieldValues(this.itemAmountInputTargets);

    const allTitlesAreValid = this.allTitlesAreValid(this.itemTitleInputTargets);
    const allAmountsAreValid = this.allAmountsAreValid(this.itemAmountInputTargets);

    if (!(allTitlesAreValid && allAmountsAreValid)) { //|| invalidTitles) {
      this.setCalculationError();
      this.showCalculationsErrorUI();

      return;
    }

    const invoiceTotal = sum(map(amounts, (amount) => Number(amount)));

    const extrasTotal = rows.reduce((accumulator, currentRow) => {
      const itemValue = Number(currentRow.querySelector(".item-value").value);
      const isExtra = currentRow.querySelector(".toggle-switch input[type='checkbox']").checked;

      return isExtra ? accumulator += itemValue : accumulator;
    }, 0);

    this.invoiceTotal = invoiceTotal;
    this.extrasTotal = extrasTotal;
    this.leasableTotal = this.invoiceTotal - this.extrasTotal;
    this.extrasPercentage = Math.ceil((this.extrasTotal / this.invoiceTotal) * 100);
    this.leasablePercentage = 100 - this.extrasPercentage;
    this.calculationResult = this.leasablePercentage >= 70 ? "pass" : "fail";
    this.extrasItemsCount = this.countExtraItems();
    this.leasableItemsCount = this.rowTargets.length - this.extrasItemsCount;

    this.updateCalculationsUI();
  }

  addItem() {
    if (this.rowTargets.length >= 20) {
      this.disableAddItemButton();
      return;
    }

    const rowItem = document.createElement("div");
    rowItem.classList.add('row', 'item');
    rowItem.setAttribute("data-target", "leasable-goods-calculator.row");

    rowItem.innerHTML = `
      <div class="col-xs-12 col-sm-1">
        <div class="remove-item btn btn-link" data-action="click->leasable-goods-calculator#removeItem">
          <i class="fa fa-times"></i>
        </div>
      </div>
      <div class="col-xs-12 col-sm-4 field-container">
        <input
          class="form-control
          item-title" type="text"
          data-action="blur->leasable-goods-calculator#handleTitleChange"
          data-target="leasable-goods-calculator.itemTitleInput"
        >
        <span class="error-message hidden"></span>
      </div>
      <div class="col-xs-12 col-sm-4 field-container">
        <div class="form-group input-group">
          <div class="input-group-addon">$</div>
          <input
            class="form-control item-value"
            type="text"
            data-action="blur->leasable-goods-calculator#handleAmountChange"
            data-target="leasable-goods-calculator.itemAmountInput"
          >
        </div>
        <span class="error-message hidden"></span>
      </div>
      <div class="col-xs-12 col-sm-3">
        <div class="toggle-switch-container" data-controller="toggle" data-action="input->toggle#toggle">
        <label class="toggle-switch">
          <input type="checkbox" data-action="input->leasable-goods-calculator#calculate" data-target="leasable-goods-calculator.checkbox">
          <span>
            <span class="active" data-target="toggle.option1">${this.translations.toggles.leasable}</span>
            <span data-target="toggle.option2">${this.translations.toggles.extra}</span>
          </span>
          <a></a>
        </label>
        </div>
      </div>
    `;

    this.rowsContainerTarget.append(rowItem);
    this.enableCalculator();
  }

  removeItem(e) {
    const closestRowItem = e.target.closest(".row.item");
    closestRowItem.remove();

    if (this.rowTargets.length == 0) {
      this.resetCalculator();
    } else {
      this.calculate();
    }

    this.enableAddItemButton();
  }

  clear() {
    this.clearRows();
    this.resetCalculator();
    this.enableAddItemButton();
  }

  enableCalculator() {
    this.element.classList.remove("empty");
  }

  resetCalculator() {
    this.element.classList.add("empty");

    this.clearRows();
    this.resetCalculations();
    this.updateCalculationsUI();
  }

  clearRows() {
    this.rowTargets.forEach((row) => {
      row.remove();
    });
  }

  disableAddItemButton() {
    this.addItemButtonTarget.disabled = true;
  }

  enableAddItemButton() {
    this.addItemButtonTarget.disabled = false;
  }
}
