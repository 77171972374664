import {Controller} from "stimulus";

// TODO: this whole controller will be able to be removed
export default class extends Controller {
  static targets = [
    "ldwCheckBox",
    "benefitsPlusCheckBox",
    "liabilityAndBenefitsPlus",
    "liabilityOnly",
    "benefitsPlusOnly",
  ];

  optionalServiceToggled() {
    let ldwEnabled = this.ldwCheckBoxTarget.checked
    let benefitsPlusEnabled = this.benefitsPlusCheckBoxTarget.checked

    if (ldwEnabled && benefitsPlusEnabled) {
      this.showElement(this.liabilityAndBenefitsPlusTarget);
      this.hideElement(this.liabilityOnlyTarget);
      this.hideElement(this.benefitsPlusOnlyTarget);
    } else {
      if (ldwEnabled) {
        this.hideElement(this.liabilityAndBenefitsPlusTarget);
        this.showElement(this.liabilityOnlyTarget);
        this.hideElement(this.benefitsPlusOnlyTarget);
      }
      if (benefitsPlusEnabled) {
        this.hideElement(this.liabilityAndBenefitsPlusTarget);
        this.hideElement(this.liabilityOnlyTarget);
        this.showElement(this.benefitsPlusOnlyTarget);
      }
      if (!ldwEnabled && !benefitsPlusEnabled) {
        this.hideElement(this.liabilityAndBenefitsPlusTarget);
        this.hideElement(this.liabilityOnlyTarget);
        this.hideElement(this.benefitsPlusOnlyTarget);
      }
    }
  }

  hideElement(element) {
    element.classList.add("hidden");
  }

  showElement(element) {
    element.classList.remove("hidden");
  }
}
