import {Controller} from "stimulus";

export default class extends Controller {
  static targets = [
    "achEnabled",
    "achEnabledLabel",
    "allowCardInApplication",
    "auditElectronicSla",
    "auditVerificationOfDelivery",
    "cardInApplicationFeeWaived",
    "cardInApplicationFeeWaivedControl",
    "checkEnabled",
    "checkEnabledLabel",
    "customerVirtualCardEligible",
    "customerVirtualCardEnabled",
    "customerVirtualCardEnabledLabel",
    "customerVirtualCardFunding",
    "fundingLocationLevelAdjustments",
    "fundingRequireCustomerInfoVerification",
    "fundingRequireDriversLicense",
    "fundingRequireMerchantInvoice",
    "initialPaymentPosExclusive",
    "instantFundingEligible",
    "instantFundingEnabled",
    "instantFundingEnabledLabel",
    "merchantDirectVirtualCardEnabled",
    "merchantDirectVirtualCardEnabledLabel",
    "paidByOracleEnabled",
    "paidByOracleEnabledLabel",
    "primaryIndustry",
    "rentCenterEnabled",
    "rentCenterEnabledLabel",
    "underwritingGradeOptions",
    "virtualCardEligible",
    "virtualCardEnabled",
    "virtualCardEnabledLabel",
    "virtualCardFunding",
  ];

  connect() {
    this.setupEventHandlers();
    this.loadDefaults();
    this.loadUIState();
    this.togglePaidByOracle();
  }

  loadUIState() {
    if (this.checkEnabledTarget.checked) {
      this.setupCheckDependentControls(true);
    } else if (this.virtualCardEnabledTarget.checked) {
      this.setupVirtualCardDependentControls(true);
    } else if (this.customerVirtualCardEnabledTarget.checked) {
      this.setupCustomerVirtualCardDependentControls(true);
    } else if (this.merchantDirectVirtualCardEnabledTarget.checked) {
      this.setupMerchantDirectVirtualCardDependentControls(true);
    } else if (this.instantFundingEnabledTarget.checked) {
      this.setupInstantFundingDependentControls(true);
    }
  }

  get parsedDefaults() {
    const defaults = this.selectedUnderwritingGrade.defaults
    return JSON.parse(defaults)
  }

  loadDefaults() {
    this.overrideSelectors().forEach((selector) => {
      this.handleAllowOverrides(selector);
      this.handleDefaults(selector);
    });
    this.handleVirtualCard();
    this.handleCustomerVirtualCard();
    this.handleMerchantDirectVirtualCard();
    this.handleInstantFunding();
    if(this.hasCardInApplicationFeeWaivedControlTarget){
      this.handleAllowCardInApplication();
    }
  }

  handleDefaults(selector) {
    const attributeName = selector.id.replace("merchant_settings_", "")
        .replace("_override", "");
    const text = this.parsedDefaults[attributeName] ? "Yes" : "No";

    selector.options[0].text = (`default (${text})`);
  }

  setupEventHandlers() {
    const controller = this;

    this.instantFundingEligibleTarget.addEventListener("change", (event) => {
      if (this.instantFundingAvailable()) {
        this.enableInstantFunding();
      } else {
        if (this.instantFundingEnabledTarget.checked) {
          this.setupInstantFundingDependentControls(false);
        }
        this.disableInstantFunding();
      }
    });

    this.virtualCardEligibleTarget.addEventListener("change", (event) => {
      if (this.virtualCardAvailable()) {
        this.enableVirtualCard();
      } else {
        if (this.virtualCardEnabledTarget.checked) {
          this.setupVirtualCardDependentControls(false);
        }
        this.disableVirtualCard();
      }
    });

    this.customerVirtualCardEligibleTarget.addEventListener("change", (event) => {
      if (this.customerVirtualCardAvailable()) {
        this.enableCustomerVirtualCard();
      } else {
        if (this.customerVirtualCardEnabledTarget.checked) {
          this.setupCustomerVirtualCardDependentControls(false);
        }
        this.disableCustomerVirtualCard();
      }
    });

    this.instantFundingEnabledTarget.addEventListener("change", (event) => {
      controller.setupInstantFundingDependentControls(event.target.checked);
    });

    this.virtualCardEnabledTarget.addEventListener("change", (event) => {
      controller.setupVirtualCardDependentControls(event.target.checked);
    });

    this.customerVirtualCardEnabledTarget.addEventListener("change", (event) => {
      controller.setupCustomerVirtualCardDependentControls(event.target.checked);
    });

    this.merchantDirectVirtualCardEnabledTarget.addEventListener("change", (event) => {
      controller.setupMerchantDirectVirtualCardDependentControls(event.target.checked);
    });

    this.checkEnabledTarget.addEventListener("change", (event) => {
      controller.setupCheckDependentControls(event.target.checked);
    });

    this.rentCenterEnabledTarget.addEventListener("change", (event) => {
      controller.setupRentCenterDependentControls(event.target.checked);
    });

    if (this.hasCardInApplicationFeeWaivedControlTarget){
      this.allowCardInApplicationTarget.addEventListener("change", (event) => {
        if (
          event.target.value === "true" ||
          (event.target.value === "" &&
            this.parsedDefaults["allow_card_in_application"] === true)
        ) {
          return this.enableCardInApplicationFeeWaivedControl();
        }

        this.disableCardInApplicationFeeWaivedControl();
      });

      this.cardInApplicationFeeWaivedControlTarget.addEventListener(
        "change",
        (event) => {
          if (event.target.value === "true") {
            return (this.cardInApplicationFeeWaivedTarget.value = true);
          }
          return (this.cardInApplicationFeeWaivedTarget.value = false);
        }
      );
    }
  }

  /** TODO: The disabling of fields should be removed */
  handleAllowOverrides(selector) {
    if (selector.id == "merchant_settings_allow_card_in_application_override") {
      selector.disabled = false;
    } else if (this.selectedUnderwritingGrade.allowOverrides == "false") {
      selector.disabled = true;
    } else {
      selector.disabled = false;
    }
  }

  handleVirtualCard() {
    if (this.virtualCardAvailable()) {
      this.enableVirtualCard();
    } else {
      // prevent virtual cards from being selected for unpermitted grades
      this.disableVirtualCard();
    }
  }

  handleMerchantDirectVirtualCard() {
    if (this.merchantDirectVirtualCardAvailable()) {
      this.enableMerchantDirectVirtualCard();
    } else {
      // prevent merchant direct virtual cards from being selected for unpermitted grades
      this.disableMerchantDirectVirtualCard();
    }
  }

  handleCustomerVirtualCard() {
    if (this.customerVirtualCardAvailable()) {
      this.enableCustomerVirtualCard();
    } else {
      // prevent customer virtual cards from being selected for unpermitted grades
      this.disableCustomerVirtualCard();
    }
  }

  virtualCardEligibleByRating(value) {
    return value === "true" || (value == "" && this.virtualCardEligibleDefault);
  }

  customerVirtualCardEligibleByRating(value) {
    return value === "true" || (value == "" && this.virtualCardEligibleDefault);
  }

  instantFundingEligibleByRating(value) {
    return value === "true" || (value == "" && this.instantFundingEligibleDefault);
  }

  virtualCardAvailable() {
    if (this.checkEnabledTarget.checked === true || this.instantFundingEnabledTarget.checked === true || this.rentCenterEnabledTarget.checked || this.paidByOracleEnabledLabelTarget.checked === true) {
      return false;
    }

    if (this.virtualCardEligibleTarget.selectedIndex > 0) {
      return this.virtualCardEligibleTarget.value === "true";
    } else {
      return this.virtualCardEligibleDefault;
    }
  }

  customerVirtualCardAvailable() {
    if (this.checkEnabledTarget.checked === true || this.instantFundingEnabledTarget.checked === true || this.rentCenterEnabledTarget.checked || this.instantFundingEnabledTarget.checked === true || this.rentCenterEnabledTarget.checked || this.paidByOracleEnabledLabelTarget.checked === true) {
      return false;
    }

    if (this.customerVirtualCardEligibleTarget.selectedIndex > 0) {
      return this.customerVirtualCardEligibleTarget.value === "true";
    } else {
      return this.customerVirtualCardEligibleDefault;
    }
  }

  merchantDirectVirtualCardAvailable() {
    if (this.checkEnabledTarget.checked === true || this.instantFundingEnabledTarget.checked === true || this.rentCenterEnabledTarget.checked || this.instantFundingEnabledTarget.checked === true || this.rentCenterEnabledTarget.checked || this.paidByOracleEnabledLabelTarget.checked=== true) {
      return false;
    }
    return true;
  }

  instantFundingAvailable() {
    if (this.checkEnabledTarget.checked === true || this.virtualCardEnabledTarget.checked === true || this.rentCenterEnabledTarget.checked || this.instantFundingEnabledTarget.checked === true || this.rentCenterEnabledTarget.checked || this.paidByOracleEnabledLabelTarget.checked === true) {
      return false;
    }

    if (this.instantFundingEligibleTarget.selectedIndex > 0) {
      return this.instantFundingEligibleTarget.value === "true";
    } else {
      return this.instantFundingEligibleDefault;
    }
  }

  handleInstantFunding() {
    if (this.instantFundingAvailable()) {
      this.enableInstantFunding();
    } else {
      // prevent virtual cards from being selected for unpermitted grades
      this.disableInstantFunding();
    }
  }

  handleAllowCardInApplication(){
    if (
      this.allowCardInApplicationTarget.selectedIndex > 0 ||
      this.parsedDefaults["allow_card_in_application"] === true
    ) {
      return this.enableCardInApplicationFeeWaivedControl();
    }

    this.disableCardInApplicationFeeWaivedControl();
  }

  overrideSelectors() {
    const inputs = [];
    inputs.push(this.fundingRequireMerchantInvoice);
    inputs.push(this.fundingRequireDriversLicense);
    inputs.push(this.fundingRequireCustomerInfoVerification);
    inputs.push(this.auditElectronicSla);
    inputs.push(this.auditVerificationOfDelivery);
    inputs.push(this.initialPaymentPosExclusive);
    inputs.push(this.allowCardInApplication);
    inputs.push(this.fundingLocationLevelAdjustments);
    inputs.push(this.virtualCardEligible);
    inputs.push(this.customerVirtualCardEligible);
    inputs.push(this.instantFundingEligible);
    return inputs;
  }

  // ....
  setupInstantFundingDependentControls(enabled) {
    if (enabled) {
      this.disableVirtualCard();
      this.disableCustomerVirtualCard();
      this.disableMerchantDirectVirtualCard();
    } else {
      this.enableVirtualCard();
      this.enableCustomerVirtualCard();
      this.enableMerchantDirectVirtualCard();
    }
  }

  setupVirtualCardDependentControls(enabled) {
    if (enabled) {
      this.disableInstantFunding();
    } else if (this.instantFundingAvailable()) {
      this.enableInstantFunding();
    }
    if (enabled) {
      this.disableACH();
      this.disableCheck();
      this.disableRentCenter();
      this.disablePaidByOracle();
      this.disableCustomerVirtualCard();
      this.disableMerchantDirectVirtualCard();
    } else {
      this.enableACH();
      this.enableCheck();
      this.enableRentCenter();
      this.enablePaidByOracle();
      this.enableCustomerVirtualCard();
      this.enableMerchantDirectVirtualCard();
    }
  }

  setupCustomerVirtualCardDependentControls(enabled) {
    if (enabled) {
      this.disableInstantFunding();
    } else if (this.instantFundingAvailable()) {
      this.enableInstantFunding();
    }
    if (enabled) {
      this.disableACH();
      this.disableCheck();
      this.disableRentCenter();
      this.disablePaidByOracle();
      this.disableVirtualCard();
      this.disableMerchantDirectVirtualCard();
    } else {
      this.enableACH();
      this.enableCheck();
      this.enableRentCenter();
      this.enablePaidByOracle();
      this.enableVirtualCard();
      this.enableMerchantDirectVirtualCard();
    }
  }

  setupMerchantDirectVirtualCardDependentControls(enabled) {
    if (enabled) {
      this.disableInstantFunding();
    } else if (this.instantFundingAvailable()) {
      this.enableInstantFunding();
    }
    if (enabled) {
      this.disableACH();
      this.disableCheck();
      this.disableRentCenter();
      this.disablePaidByOracle();
      this.disableVirtualCard();
      this.disableCustomerVirtualCard();
    } else {
      this.enableACH();
      this.enableCheck();
      this.enableRentCenter();
      this.enablePaidByOracle();
      this.enableVirtualCard();
      this.enableCustomerVirtualCard();
    }
  }

  setupCheckDependentControls(enabled) {
    if (enabled) {
      this.disableInstantFunding();
    } else if (this.instantFundingAvailable()) {
      this.enableInstantFunding();
    }
    if (enabled) {
      this.disableACH();
      this.disableVirtualCard();
      this.disableCustomerVirtualCard();
      this.disableMerchantDirectVirtualCard();
      this.disableRentCenter();
      this.disablePaidByOracle();
    } else {
      this.enableACH();
      this.enableVirtualCard();
      this.enableCustomerVirtualCard();
      this.enableMerchantDirectVirtualCard();
      this.enableRentCenter();
      this.enablePaidByOracle();
    }
  }

  setupPaidByOracleDependentControls() {
    const enabled = this.paidByOracleEnabledTarget.checked

    if (enabled) {
      this.disableInstantFunding();
    } else if (this.instantFundingAvailable()) {
      this.enableInstantFunding();
    }
    if (enabled) {
      this.disableACH();
      this.disableCheck();
      this.disableVirtualCard();
      this.disableCustomerVirtualCard();
      this.disableMerchantDirectVirtualCard();
      this.disableRentCenter();
    } else {
      this.enableACH();
      this.enableCheck();
      this.enableVirtualCard();
      this.enableCustomerVirtualCard();
      this.enableMerchantDirectVirtualCard();
      this.enableRentCenter();
    }
  }

  setupRentCenterDependentControls(enabled) {
    if (enabled) {
      this.disableInstantFunding();
    } else if (this.instantFundingAvailable()) {
      this.enableInstantFunding();
    }
    if (enabled) {
      this.disableACH();
      this.disableCheck();
      this.disableVirtualCard();
      this.disableCustomerVirtualCard();
      this.disableMerchantDirectVirtualCard();
      this.disablePaidByOracle();
    } else {
      this.enableACH();
      this.enableCheck();
      this.enableVirtualCard();
      this.enableCustomerVirtualCard();
      this.enableMerchantDirectVirtualCard();
      this.enablePaidByOracle();
    }
  }

  enableVirtualCard() {
    if (this.virtualCardAvailable()) {
      this.virtualCardEnabledTarget.disabled = false;
      this.virtualCardEnabledLabelTarget.parentElement.classList.remove("disabled");
    }
  }

  enableCustomerVirtualCard() {
    if (this.customerVirtualCardAvailable()) {
      this.customerVirtualCardEnabledTarget.disabled = false;
      this.customerVirtualCardEnabledLabelTarget.parentElement.classList.remove("disabled");
    }
  }

  disableVirtualCard() {
    this.virtualCardEnabledTarget.disabled = true;
    this.virtualCardEnabledTarget.checked = false;
    this.virtualCardEnabledTarget.parentElement.classList.remove("checked");
    this.virtualCardEnabledLabelTarget.parentElement.classList.add("disabled");
  }

  disableCustomerVirtualCard() {
    this.customerVirtualCardEnabledTarget.disabled = true;
    this.customerVirtualCardEnabledTarget.checked = false;
    this.customerVirtualCardEnabledTarget.parentElement.classList.remove("checked");
    this.customerVirtualCardEnabledLabelTarget.parentElement.classList.add("disabled");
  }

  enableRentCenter() {
    this.rentCenterEnabledTarget.disabled = false;
    this.rentCenterEnabledLabelTarget.parentElement.classList.remove("disabled");
  }

  togglePaidByOracle() {
    this.setupPaidByOracleDependentControls();
  }

  disableRentCenter() {
    this.rentCenterEnabledTarget.disabled = true;
    this.rentCenterEnabledTarget.checked = false;
    this.rentCenterEnabledTarget.parentElement.classList.remove("checked");
    this.rentCenterEnabledLabelTarget.parentElement.classList.add("disabled");
  }

  enableMerchantDirectVirtualCard() {
    this.merchantDirectVirtualCardEnabledTarget.disabled = false;
    this.merchantDirectVirtualCardEnabledLabelTarget.parentElement.classList.remove("disabled");
  }

  disableMerchantDirectVirtualCard() {
    this.merchantDirectVirtualCardEnabledTarget.disabled = true;
    this.merchantDirectVirtualCardEnabledTarget.checked = false;
    this.merchantDirectVirtualCardEnabledTarget.parentElement.classList.remove("checked");
    this.merchantDirectVirtualCardEnabledLabelTarget.parentElement.classList.add("disabled");
  }

  enableCheck() {
    this.checkEnabledTarget.disabled = false;
    this.checkEnabledLabelTarget.parentElement.classList.remove("disabled");
  }

  disableCheck() {
    this.checkEnabledTarget.disabled = true;
    this.checkEnabledTarget.checked = false;
    this.checkEnabledTarget.parentElement.classList.remove("checked");
    this.checkEnabledLabelTarget.parentElement.classList.add("disabled");
  }

  enableACH() {
    this.achEnabledTarget.disabled = false;
    this.achEnabledLabelTarget.parentElement.classList.remove("disabled");
  }

  disableACH() {
    this.achEnabledTarget.disabled = true;
    this.achEnabledTarget.checked = false;
    this.achEnabledTarget.parentElement.classList.remove("checked");
    this.achEnabledLabelTarget.parentElement.classList.add("disabled");
  }


  enableInstantFunding() {
    if (this.instantFundingAvailable()) {
      this.instantFundingEnabledTarget.disabled = false;
      this.instantFundingEnabledLabelTarget.parentElement.classList.remove("disabled");
    }
  }

  disableInstantFunding() {
    this.instantFundingEnabledTarget.disabled = true;
    this.instantFundingEnabledTarget.checked = false;
    this.instantFundingEnabledTarget.parentElement.classList.remove("checked");
    this.instantFundingEnabledLabelTarget.parentElement.classList.add("disabled");
  }

  enableCardInApplicationFeeWaivedControl(){
    this.cardInApplicationFeeWaivedControlTarget.disabled = false;
    this.cardInApplicationFeeWaivedControlTarget.parentElement.classList.remove(
      "disabled"
    );
  }

  disableCardInApplicationFeeWaivedControl(){
    this.cardInApplicationFeeWaivedTarget.value = "false";
    this.cardInApplicationFeeWaivedControlTarget.value = "false";
    this.cardInApplicationFeeWaivedControlTarget.disabled = true;
    this.cardInApplicationFeeWaivedControlTarget.parentElement.classList.add(
      "disabled"
    );
  }

  virtualCardValue(element) {
    return this.virtualCardFundingTarget.value;
  }

  customerVirtualCardValue(element) {
    return this.customerVirtualCardFundingTarget.value;
  }

  get virtualCardFunding() {
    return this.virtualCardFundingTarget;
  }

  get customerVirtualCardFunding() {
    return this.customerVirtualCardFundingTarget;
  }

  get paymentMethodSelector() {
    return this.paymentMethodSelectorTarget;
  }

  get auditVerificationOfDelivery() {
    return this.auditVerificationOfDeliveryTarget;
  }

  get auditElectronicSla() {
    return this.auditElectronicSlaTarget;
  }

  get fundingRequireCustomerInfoVerification() {
    return this.fundingRequireCustomerInfoVerificationTarget;
  }

  get fundingRequireDriversLicense() {
    return this.fundingRequireDriversLicenseTarget;
  }

  get fundingRequireMerchantInvoice() {
    return this.fundingRequireMerchantInvoiceTarget;
  }

  get initialPaymentPosExclusive() {
    return this.initialPaymentPosExclusiveTarget;
  }

  get selectedUnderwritingGrade() {
    return this.underwritingGradeOptionsTarget.selectedOptions[0].dataset;
  }

  get instantFundingEligible() {
    return this.instantFundingEligibleTarget;
  }

  get virtualCardEligible() {
    return this.virtualCardEligibleTarget;
  }

  get customerVirtualCardEligible() {
    return this.customerVirtualCardEligibleTarget;
  }

  /** Card-in-App Dropdown Target */
  get allowCardInApplication() {
    return this.allowCardInApplicationTarget;
  }

  /** Location Level Adjustments dropdown */
  get fundingLocationLevelAdjustments() {
    return this.fundingLocationLevelAdjustmentsTarget;
  }

  get instantFundingEligibleDefault() {
    return this.data.get("instantFundingEligibleDefault") === "true";
  }

  get virtualCardEligibleDefault() {
    return this.data.get("virtualCardEligibleDefault") === "true";
  }

  get customerVirtualCardEligibleDefault() {
    return this.data.get("customerVirtualCardEligibleDefault") === "true";
  }
}
