import { Controller } from "stimulus";
import {
  flash,
} from "../../../shared/common/notices/flash_alerts_controller";
import {
  showSpinnerDialog,
} from "../../../shared/common/dialogs/spinner_dialog_controller";

/** Confirm force satisfy initial payment*/
export default class extends Controller {
  static targets = [
    "dialog",
    "submitBtn"
  ];

  connect() {
    dialogPolyfill.registerDialog(this.element);
  }

  closeDialog() {
    this.element.close();
  }

  showDialog() {
    this.element.showModal();
  }

  confirmSatsifyIP() {
    const url = new URL(new URL(window.location).origin);
    url.pathname = this.dialogTarget.dataset.forceSatisfyUrl
    const data = {
      contract_id: this.dialogTarget.dataset.contractId
    };
    const fetchInit = window.acima.fetchInit({
      method: "POST",
      body: JSON.stringify(data)
    });

    this.submitBtnTarget.disabled = true;

    fetch(url.toString(), fetchInit).then(
      response => {
        return response.json();
      }).then(data => {
      if (data.success) {
        flash.success(data.message);
      } else {
        console.log(data.message + ": " + data.error);
        flash.error(data.message);
      }
      window.scrollTo({ top: 0, behavior: "smooth" });
      showSpinnerDialog("");
      window.location.reload();
    });
  }
}
